import React from "react";
import { graphql } from "gatsby";
import { Container, Breadcrumb } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import PageHeader from "../components/PageHeader";
import BlogSingle from "../components/BlogSingle";
import Footer from "../components/Footer";

const ContentPage = ({ data, pageContext }) => {
  console.log("ContentPage", data);
  return (
    <Layout pageTitle={`${data.strapiPost.Title} | MyFoodProtocol`}>
      <Header inner={true} btnClass="main-nav__btn-two" />
      <MobileMenu inner={true} />
      <PageHeader
        pageHeading={data.strapiPost.Title}
        breadcrumb={[{ url: "#", title: data.strapiPost.Title }]}
      />

      <section className="blog-details">
        <Container>
          <Row>
            <Col lg={12} xs={12}>
              <BlogSingle data={data.strapiPost} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  );
};

export default ContentPage;

export const query = graphql`
  query PostQuery($id: Int) {
    strapiPost(strapi_id: { eq: $id }) {
      strapi_id
      id
      Title
      Date
      Type
      Lead {
        data {
          Lead
        }
      }
      Image {
        url
        width
        height
        formats {
          large {
            url
            width
            height
          }
        }
      }
      Content {
        data {
          Content
        }
      }
      tags {
        Name
        strapi_id
        id
      }
    }
  }
`;
